exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-doctors-dr-eliscar-hewett-tsx": () => import("./../../../src/pages/doctors/dr-eliscar-hewett.tsx" /* webpackChunkName: "component---src-pages-doctors-dr-eliscar-hewett-tsx" */),
  "component---src-pages-doctors-dr-narain-tsx": () => import("./../../../src/pages/doctors/dr-narain.tsx" /* webpackChunkName: "component---src-pages-doctors-dr-narain-tsx" */),
  "component---src-pages-doctors-dr-quartey-tsx": () => import("./../../../src/pages/doctors/dr-quartey.tsx" /* webpackChunkName: "component---src-pages-doctors-dr-quartey-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invisalign-dentist-brooklyn-tsx": () => import("./../../../src/pages/invisalign-dentist-brooklyn.tsx" /* webpackChunkName: "component---src-pages-invisalign-dentist-brooklyn-tsx" */),
  "component---src-pages-invisalign-success-tsx": () => import("./../../../src/pages/invisalign-success.tsx" /* webpackChunkName: "component---src-pages-invisalign-success-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-park-slope-family-dentistry-services-tsx": () => import("./../../../src/pages/park-slope-family-dentistry-services.tsx" /* webpackChunkName: "component---src-pages-park-slope-family-dentistry-services-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-tongue-tie-tsx": () => import("./../../../src/pages/tongue-tie.tsx" /* webpackChunkName: "component---src-pages-tongue-tie-tsx" */)
}

